<template>
  <div>
    <el-form :model="customerInfo" ref="customerInfo" :rules="editRules" class="demo-ruleForm">
      <el-row>
        <el-col :span="8">
          <el-form-item label="客户名称" label-width="100px" prop="name">
            <el-input v-model="customerInfo.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系人" label-width="100px" prop="contact">
            <el-input v-model="customerInfo.contact"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="电话" label-width="100px" prop="phone">
            <el-input  v-model="customerInfo.phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="类型" label-width="100px" prop="type">
            <el-radio-group v-model="customerInfo.type">
              <el-radio :label="0">潜在</el-radio>
              <el-radio :label="1">合作</el-radio>
              <el-radio :label="2">流失</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="级别" label-width="100px" prop="level">
            <el-radio-group v-model="customerInfo.level">
              <el-radio :label="0">A</el-radio>
              <el-radio :label="1">B</el-radio>
              <el-radio :label="2">C</el-radio>
              <el-radio :label="3">C</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="标签" label-width="100px" prop="tag">
            <el-button class="button-new-tag" size="small" @click="handle_newTag()">+ 添加标签</el-button>
            <el-tag style="margin-left: 5px;"
                v-for="(tag, index) in customerCheckedTags"
                :key="tag.name"
                closable
                @close="handle_closeTag(index)"
                >
              {{tag.name}}
            </el-tag>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="省份" label-width="100px" prop="province">
            <el-select @change="handler_changeProvince" v-model="customerInfo.province" placeholder="请选择省级地址">
              <el-option
                  v-for="item in selectProvinceList"
                  :key="item.id"
                  :label="item.areaName"
                  :value="item.areaName"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="城市" label-width="100px" prop="city">
            <el-select @change="handler_changeCity" v-model="customerInfo.city" placeholder="请选择市级地址">
              <el-option
                  v-for="item in selectCityList"
                  :key="item.id"
                  :label="item.areaName"
                  :value="item.areaName"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="区域" label-width="100px" prop="area">
            <el-select v-model="customerInfo.area" placeholder="请选择县区">
              <el-option
                  v-for="item in selectAreaList"
                  :key="item.id"
                  :label="item.areaName"
                  :value="item.areaName"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="详细地址" label-width="100px" prop="address">
            <el-input v-model="customerInfo.address"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button v-if="auth['customer:edit']" type="primary" @click="edit_save('customerInfo')">确 定</el-button>
    </div>

    <hr style="margin-top: 20px;"/>
    <el-row style="margin-top: 20px; margin-bottom: 10px;">
      <el-col :span="24" style="text-align: center; font-weight: bold;">仓库信息</el-col>
    </el-row>

    <warehouseList :customerInfo="customerInfo"></warehouseList>

    <CustomerTagListDig :customer-info="customerInfo" :edit-dig-show="customerTagSelectShow" :visible.sync="customerTagSelectShow" v-if="customerTagSelectShow"></CustomerTagListDig>

  </div>

</template>

<script>
import CUS from '../../api/biz/api_customer';
import WAREHOUSE from '../../api/biz/api_customerwarehouse';
import warehouseList from './warehouseList';
import CustomerTagListDig from './tagSelectDialog';
import COMM from "@/api/base/api_comm";
import AREA from "@/api/base/api_area";

export default {
  name: 'customeredit',
  components: {
    warehouseList,
    CustomerTagListDig
  },
  props: {
    customerId: {
      type: Number
    }
  },
  data(){
    return {
      showLoading: false,
      // page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      editRules: {
        name: [{required: true, message: '请输入客户名称', trigger: 'blur'}, {
          min: 2,
          max: 25,
          message: '长度在 2 到 25 个字符',
          trigger: 'blur'
        }],
        type: [{required: true, message: '请选择客户类型！', trigger: 'blur'}]
      },
      customerInfo: {
        id: this.$route.params.customerid
      },
      customerTagSelectShow: false,
      customerTagList: [],
      customerCheckedTags: [],

      selectProvinceList: [],
      selectCityList: [],
      selectAreaList: [],
      auth: {}
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.init_base();
      this.auth=this.$getAuth('/customer');
      this.initPageData();
    },

    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'customertag'}).then((res) => {
        this.customerTagList = res;
      });

      this.initcustomerInfoAddr();
    },

    initPageData() {
      // 客户ID
      if (this.customerId == null) {
        this.customerInfo.id = this.$route.params.customerid
      } else {
        this.customerInfo.id = this.customerId;
      }

      if (this.customerInfo.id == null || this.customerInfo.id == 'null') {
        return;
      }

      CUS.get({
        id: this.customerInfo.id
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          this.customerInfo = res.data;
          // 构造客户标签
          this.buildCustomerTags(this.customerInfo);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    buildCustomerTags(customerInfo) {
      if (customerInfo == null) {
        return;
      }

      let tag = customerInfo.tag || '';
      for (const index in this.customerTagList) {
        let customerTag = this.customerTagList[index];
        if (tag.indexOf(customerTag['value'] + ',') >= 0) {
          this.customerCheckedTags.push(customerTag);
        }
      }
    },

    initcustomerInfoAddr() {
      this.initProvinceList();
      this.initCityList();
      this.initAreaList();
    },

    initProvinceList() {
      AREA.listRoot().then((res) => {
        if (200 == res.code) {
          this.selectProvinceList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    initCityList() {
      if (this.customerInfo?.province) {
        let params = { parentName: this.customerInfo.province };
        AREA.listChildrenByParentName(params).then((res) => {
          if (200 == res.code) {
            this.selectCityList = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    initAreaList() {
      if (this.customerInfo?.city) {
        let params = { parentName: this.customerInfo?.city };
        AREA.listChildrenByParentName(params).then((res) => {
          if (200 == res.code) {
            this.selectAreaList = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    handler_changeProvince(value) {
      if (value) {
        this.customerInfo.province = value;
        this.customerInfo.city = undefined;
        this.customerInfo.area = undefined;
        this.selectCityList = [];
        this.selectAreaList = [];
        this.initCityList();
      }
    },
    handler_changeCity(value) {
      if (value) {
        this.customerInfo.city = value;
        this.customerInfo.area = undefined;
        this.selectAreaList = [];
        this.initAreaList();
      }
    },

    edit_save(formName) {
      let curPage = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.pmInfo)

          let tagstr = '';
          for (const index in this.customerCheckedTags) {
            let customerTag = this.customerCheckedTags[index];
            tagstr = tagstr + customerTag['value'] + ',';
          }
          this.customerInfo.tag = tagstr;

          if (this.customerInfo.id > 0) {
            CUS.update(this.customerInfo).then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.customerInfo = res.data;
                this.$parent.editShow=false;
                this.$parent.initPageData();
              } else {
                this.$message.error(res.msg);
              }
            }).catch(() => console.log('promise catch err'));
          } else {
            CUS.add(this.customerInfo).then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.customerInfo = res.data;
                this.$parent.editShow=false;
                this.$parent.initPageData();
              } else {
                this.$message.error(res.msg);
              }
            }).catch(() => console.log('promise catch err'));
          }

        } else {
          return false;
        }
      });
    },

    handle_newTag() {
      this.customerTagSelectShow = true;
    },
    handle_closeTag(index) {
      this.customerCheckedTags.splice(index, 1);
    }
  }
}
</script>

<style scoped>
.divauto{
  margin:  10px auto 0 auto;
}

.red {
  color: #ff0000;
}
</style>