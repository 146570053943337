<template>
  <div>
    <el-dialog :title="this.title" :visible.sync="editDigShow" v-if="editDigShow" width="800px" v-model="editDigShow" :close-on-click-modal="false">
      <el-checkbox-group v-model="checkedValList">
        <el-checkbox v-for="item in customerTagList" :label="item.value" :value="parseInt(item.value)">
          <span class="checkBox"> {{item.name}}</span>
        </el-checkbox>
      </el-checkbox-group>

      <div slot="footer" class="dialog-footer" style="text-align:right; margin-top: 10px;">
        <el-button @click="handle_cancel()">关闭</el-button>
        <el-button type="primary" @click="handle_save('proInfo')">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import CUS from '../../api/biz/api_customer';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";

export default {
  name: "customertagselectdig", // 客户选择列表
  components: {
  },
  props: {
    editDigShow:{
      type: Boolean
    },
    customerInfo: {
      type: String
    }
  },
  data() {
    return {
      showLoading: false,
      title: '选择客户标签',
      customerTagList: [],
      checkedValList: [],
      checkedTagList: []
    }
  },
  // 初始化加载
  mounted() {
      this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.init_base();
      this.initPageData();
    },
    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'customertag'}).then((res) => {
        this.customerTagList = res;
      });
    },

    // 加载页面数据
    initPageData() {

    },

    devlogChange(pageNum) {
      // this.page.pageNum = pageNum;
      // this.initPageData();
    },

    // 查询
    handle_search() {
      this.initPageData();
    },

    handleCurrentChange(val) {
      // console.log(val);
      // this.currentRow = val;
    },

    // 确定
    handle_save() {
      // console.log(this.currentRow);
      // this.$parent.customerId = this.currentRow.id;
      // this.$parent.customerInfo = this.currentRow;

      let customerCheckedTagsOrg = this.$parent.customerCheckedTags;
      let customerCheckedTagsStrOrg = this.$parent.customerInfo.tag || '';

      for (const keychecked in this.checkedValList) {
        let valchecked = this.checkedValList[keychecked];
        for (const key in this.customerTagList) {
          let customerTag = this.customerTagList[key];
          if (valchecked == customerTag['value'] && customerCheckedTagsStrOrg.indexOf(valchecked) < 0) {
            this.checkedTagList.push(customerTag);
            this.$parent.customerCheckedTags.push(customerTag);
            break;
          }
        }
      }

      this.$parent.customerInfo.tag = this.$parent.customerInfo.tag + this.checkedValList.join(',');
      this.$parent.customerTagSelectShow=false;
    },

    // 取消
    handle_cancel() {
      this.$parent.customerTagSelectShow=false;
    },
  }
}
</script>